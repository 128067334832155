import React, { useEffect, useState } from 'react';
import {
  Form,
  Button,
  InputGroup,
  FormControl,
  Spinner,
  Stack,
} from 'react-bootstrap';
import { Key, Person } from 'react-bootstrap-icons';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setAuth } from 'redux/actions';
import { toast } from 'react-toastify';
import GenericUtils from 'utils/GenericUtils';
import ConfirmToast from 'modules/core/components/ConfirmToast';
import FormValidation from '../../../utils/FormValidation';
import AuthService from '../services/AuthService';

function LoginForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [usernameIsValid, setUsernameIsValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [passcode, setPasscode] = useState('');
  const [isFocus, setIsFocus] = useState({
    username: false,
    password: false,
    passcode: false,
  });

  const validate = () => setUsernameIsValid(true);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    validate();
  }, [username]);

  const cleanForm = () => {
    setUsername('');
    setPassword('');
    setPasscode('');
    setIsFocus({
      username: false,
      password: false,
      passcode: false,
    });
  };

  async function login(e) {
    e.preventDefault();
    setLoading(true);
    try {
      const authToken = await AuthService.login({ username, password, passcode });
      if (authToken) {
        if (authToken.force_change_password) {
          toast(
            <ConfirmToast
              config={{
                title: 'Password Expired',
                message: (
                  <Stack>
                    Your password has expired. Please click the link below to update it.
                    <a href={process.env.REACT_APP_REACH_URL} rel="noreferrer" target="_blank">{process.env.REACT_APP_REACH_URL}</a>
                  </Stack>
                ),
              }}
              confirm={() => {
                window.open(process.env.REACT_APP_REACH_URL, '_blank');
              }}
            />,
            GenericUtils.confirmToastOptions,
          );
        } else {
          sessionStorage.setItem('tp-access-token', authToken.token);

          dispatch(setAuth(authToken));

          if (authToken.verification.token_required) {
            navigate('/login/verify');
          } else {
            await AuthService.activateSession({ authentication_token: passcode });
            navigate('/');
          }
        }
      }
    } catch (error) {
      cleanForm();
      toast.error('Invalid credentials, please try again.');
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    (async () => {
      const loginCode = searchParams.get('code');
      if (loginCode) {
        try {
          const authToken = await AuthService.loginSSO({
            referenceKey: 'crombie',
            passcode: '12345',
            code: loginCode,
          });
          if (authToken) {
            sessionStorage.setItem('tp-access-token', authToken.token);
            await AuthService.activateSession({ authentication_token: 12345 });
            navigate('/');
          }
        } catch (e) {
          // toast.error('Something went wrong, please try again.');
        }
      }
    })();
  }, [searchParams]);

  function validateForm() {
    return username.length > 0 && password.length > 0 && passcode.length > 3;
  }

  return (
    <Form onSubmit={login}>
      <div className="title">Login</div>
      <p>
        Login to the Config Admin tool to access the configuration interface for
        all clients
      </p>
      <InputGroup size="sm" className="mb-3" hasValidation>
        <InputGroup.Text
          style={isFocus.username
            ? FormValidation.validateStyleColor(username, username.length !== 0) : {}}
          id="inputGroup-sizing-sm"
        >
          <Person />
        </InputGroup.Text>
        <FormControl
          isInvalid={!usernameIsValid}
          required
          size="sm"
          type="username"
          placeholder="User Name"
          value={username}
          onChange={(e) => {
            setUsername(e.target.value);
            setIsFocus({ ...isFocus, username: true });
          }}
        />
        <Form.Control.Feedback type="invalid">
          Invalid characters.
        </Form.Control.Feedback>
      </InputGroup>
      <InputGroup size="sm" className="mb-3">
        <InputGroup.Text
          id="inputGroup-sizing-sm"
          style={isFocus.password
            ? FormValidation.validateStyleColor(password, password.length !== 0) : {}}
        >
          <Key />
        </InputGroup.Text>
        <FormControl
          required
          size="sm"
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
            setIsFocus({ ...isFocus, password: true });
          }}
        />
      </InputGroup>
      <InputGroup size="sm" className="mb-3">
        <InputGroup.Text
          id="inputGroup-sizing-sm"
          style={isFocus.passcode
            ? FormValidation.validateStyleColor(passcode, passcode.length > 3) : {}}
        >
          <Key />
        </InputGroup.Text>
        <FormControl
          required
          size="sm"
          type="password"
          placeholder="Access PIN"
          value={passcode}
          onChange={(e) => {
            setPasscode(e.target.value);
            setIsFocus({ ...isFocus, passcode: true });
          }}
        />
      </InputGroup>
      <Button
        size="lg"
        type="submit"
        disabled={!validateForm() || !usernameIsValid}
      >
        Login
        {loading && <Spinner animation="grow" variant="light" size="sm" />}
      </Button>

      {/* OR

      <input
        type="button"
        onClick={(e) => {
          e.preventDefault();
          window.location.href = 'https://crombie.auth.us-east-1.amazoncognito.com/oauth2/authorize?identity_provider=AzureAD&redirect_uri=http://localhost:3000/login&response_type=CODE&client_id=72vul4okasqm1hcmqirgqb0rui&scope=aws.cognito.signin.user.admin email openid phone profile';
        }}
        value="AzureAD"
        className="btn
        btn-info idpButton-customizable"
        aria-label="AzureAD"
      /> */}
      <div className="subtitle">Julota Log On Policy</div>
      <p className="disclaimer">
        In accessing Julota&apos;s information systems, you acknowledge that all your activity on
        Julota information systems may be subject to monitoring and agree to comply with all
        company policies and procedures regarding company information and information systems. Any
        unauthorized use of Julota&apos;s information systems is prohibited and subject to
        disciplinary action, termination, and even legal action. Logging on to Julota&apos;s
        network confirms your acceptance of these terms.
      </p>
    </Form>
  );
}

export default LoginForm;
