/* eslint-disable max-len */
import React, {
  useCallback,
  useRef,
  useEffect,
  useState,
  useMemo,
} from 'react';
import { AgGridReact } from 'ag-grid-react';
import {
  Dropdown,
  DropdownButton,
  Button,
  Form,
} from 'react-bootstrap';
import { Plus } from 'react-bootstrap-icons';
import GenericUtils from 'utils/GenericUtils';
import hash from 'object-hash';
import ErrorList from './ErrorList';

export default function EditableTable(props) {
  const {
    question,
    value: defaultValue,
    className,
    callbackFunction,
    errors,
    onRowClick,
  } = props;
  const gridRef = useRef();
  const [gridApi, setGridApi] = useState(null);
  const [selectedValue, setSelectedValue] = useState(defaultValue);
  const [addOptions, setAddOptions] = useState(null);
  const [tableHasEmptyRows, setTableHasEmptyRows] = useState(false);

  const defaultColDef = useMemo(() => ({
    sortable: false,
    editable: true,
    filter: false,
    suppressMovable: true,
    suppressMenu: true,
    resizable: true,
    suppressContextMenu: true,
  }), []);

  const fitColumns = (params) => {
    if (question.sizeColumnsToFit) {
      params.api.sizeColumnsToFit();
    }
    if (question.autoSizeAllColumns) {
      params.api.autoSizeAllColumns();
    }
  };

  const onGridReady = (params) => {
    setGridApi(params.api);
    fitColumns(params);
    window.onresize = () => {
      fitColumns(params);
    };
  };

  const checkForEmptyRows = (api) => {
    api.forEachNode((rowNode) => {
      const rowObj = {};
      if (question.objectStructure) {
        Object.keys(question.objectStructure).forEach((key) => {
          rowObj[key] = rowNode.data[key];
        });
      }

      if (GenericUtils.areObjectsEqual(rowObj, question.objectStructure)) {
        setTableHasEmptyRows(true);
      }
    });
  };

  const onChange = (params) => {
    const api = params.api ?? gridApi;

    if (api) {
      const rows = [];
      api.forEachNodeAfterFilterAndSort((node) => rows.push(node.data));

      callbackFunction({
        target: {
          name: question.field,
          value: rows,
        },
      });
    }

    setTableHasEmptyRows(false);
    checkForEmptyRows(api);
  };

  const addNewRow = (e) => {
    e.preventDefault();

    gridApi.stopEditing();

    gridApi.applyTransactionAsync({
      add: [{ ...question.objectStructure }],
    }, () => {
      gridApi.startEditingCell({
        rowIndex: gridApi.getLastDisplayedRow(),
        colKey: question.columnDefs[0].field,
      });
    });

    setTableHasEmptyRows(true);
    checkForEmptyRows(gridApi);
  };

  const applyAction = (params, action) => {
    gridRef.current.api.applyTransaction({
      remove: [params.node.data],
      addIndex: action === 'send-top' ? 0 : null,
      add: action !== 'delete' ? [params.node.data] : [],
    });

    onChange(params);
  };

  const addFromTemplate = (e, option) => {
    e.preventDefault();
    gridApi.applyTransactionAsync({
      add: [option],
    }, () => {
      const rows = [];
      gridApi.forEachNode((node) => rows.push(node.data));

      callbackFunction({
        target: {
          name: question.field,
          value: rows,
        },
      });
    });
  };

  const getContextMenuItems = useCallback((params) => {
    let actionsList = [];

    if (question.deletable) {
      actionsList.push({
        name: 'Remove',
        action: () => applyAction(params, 'delete'),
      });
    }

    if (question.sortable) {
      actionsList = [
        ...actionsList,
        {
          name: 'Send to top of list',
          action: () => applyAction(params, 'send-top'),
        },
        {
          name: 'Send to bottom of the list',
          action: () => applyAction(params, 'send-bottom'),
        },
      ];
    }

    if (question.rowActions) {
      actionsList = [
        ...actionsList,
        ...question.rowActions.map((a) => ({
          name: a.name(params),
          action() {
            a.action(params);
            onChange(params);

            setTimeout(() => {
              params.api.redrawRows();
            }, 0);
          },
        })),
      ];
    }

    if (question.field === 'organizations') {
      if (params.node.data.relation === 'primary') {
        const filtered = actionsList.filter((action) => action.name !== 'Mark as Primary' && action.name !== 'Remove');
        if (filtered.length === 0) {
          return null;
        }

        return filtered;
      }
    }

    if (question.field === 'hubs') {
      if (params.node.data.relation === 'primary') {
        const filtered = actionsList.filter((action) => action.name !== 'Remove');
        if (filtered.length === 0) {
          return null;
        }

        return filtered;
      }
    }

    return actionsList;
  }, []);

  useEffect(() => {
    setSelectedValue(defaultValue);
    setAddOptions(question.add?.options?.filter((item) => !defaultValue?.map((i) => i.key ?? i.id).includes(item.key)));
  }, [defaultValue]);

  return (
    <Form.Group className={className || 'mb-3'}>
      <Form.Label className="label">{question.label}</Form.Label>
      {
        addOptions && (
          <DropdownButton
            title={question.add.label}
            variant="link"
            size="sm"
            disabled={question.disabled || !addOptions.length}
            className={`float-right ${addOptions.length > 0 ? 'enabled' : 'disabled'}`}
          >
            {addOptions.map((option) => (
              <Dropdown.Item id={option.id} key={hash(option)} onClick={(e) => addFromTemplate(e, option)}>
                {option.label}
              </Dropdown.Item>
            ))}
          </DropdownButton>
        )
      }

      {errors && errors.length > 0 && <ErrorList errors={errors} />}

      <div
        className={`ag-theme-alpine ag-container ${question.disabled && 'editabletable-disabled'}`}
        style={{ padding: '0px', marginTop: 5 }}
      >
        <AgGridReact
          ref={gridRef}
          rowData={selectedValue}
          columnDefs={question.columnDefs.map((column) => {
            if (!column.tooltipField && !column.tooltipComponent) {
              return {
                ...column,
                tooltipField: column.field,
              };
            }

            return column;
          })}
          defaultColDef={question.defaultColDef ?? defaultColDef}
          onGridReady={onGridReady}
          onCellValueChanged={onChange}
          onRowDragEnd={onChange}
          onRowClicked={(params) => {
            if (question.isRowClickable) {
              // eslint-disable-next-line no-unused-expressions
              !question.addInlineRow && onRowClick !== undefined ? onRowClick(params) : null;
            }
          }}
          tooltipShowDelay={1500}
          groupDisplayType="groupRows"
          animateRows={false}
          domLayout="autoHeight"
          paginationPageSize="15"
          rowSelection="single"
          rowDragManaged
          getContextMenuItems={getContextMenuItems}
          undoRedoCellEditing
          suppressPaginationPanel="false"
          suppressMoveWhenRowDragging
          suppressCellFocus={question.disabled}
          suppressClickEdit={question.disabled}
          suppressRowDrag={question.disabled}
          suppressRowClickSelection={question.disabled}
        />

        {!question.disabled && (
          <div style={{ textAlign: 'right' }}>
            {
              question.addInlineRow && (
                <Button variant="link" size="sm" onClick={addNewRow} disabled={tableHasEmptyRows}>
                  <Plus />
                  Add Row
                </Button>
              )
            }
          </div>
        )}
      </div>
    </Form.Group>
  );
}
